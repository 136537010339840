import React from "react";
import Header from "../components/Header";

const ResumePage = () => {
  return (
    <div className="container">
      <Header title={"Resume"} />
      <main>
        <section className="section">
          <h2>asdasd</h2>
          <p>asda</p>
        </section>
      </main>
    </div>
  );
};

export default ResumePage;
