import reactImg from "./react.svg";
import fullStackImg from "./fullstack.svg";
import jsImg from "./js.svg";
import HeroBgImage from "./baloons-md.jpg";
import HeaderBgImage from "./baloons-header.jpg";

export const HeaderBgImg = HeaderBgImage;
export const HeroBgImg = HeroBgImage;
export const reactIcon = reactImg;
export const fullStackIcon = fullStackImg;
export const jsIcon = jsImg;
