import React from "react";
import Header from "../components/Header";

const ContactPage = () => {
  return (
    <div className="container">
      <Header title={"Contact"} />
      <main>
        <section className="section">
          <h2>contact</h2>
          <p>asda</p>
        </section>
      </main>
    </div>
  );
};

export default ContactPage;
